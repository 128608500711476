<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Update Visit Status"
                @onClickCloseButton="onClickCloseButton"
            />
        </div>
       
        <div class="row p-2">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                <table>
                    <tr>
                        <td class="table-column">Schedule Date & Time</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <strong>{{ singlePlan.start_time }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">BDO Name</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <strong>{{ singlePlan.sr_name }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Bank-Branch Name</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <strong>{{ singlePlan.dealer_name }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Manager Details</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <button class="btn btn-primary btn-sm">
                                <i class="fas fa-eye"></i>
                                Update Info
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Contact Person Details</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <button class="btn btn-primary btn-sm">
                                <i class="fas fa-eye"></i>
                                Update Info
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Location</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <strong>{{ singlePlan.area_name }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Client Details</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <button class="btn btn-primary btn-sm">
                                <i class="fas fa-eye"></i>
                                Update Info
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Existing Clients</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <strong>{{ clientList.length }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Total Number of Cleints</td>
                        <td class="table-divider"></td>
                        <td class="table-row"> 
                            <input
                                placeholder="Number of Clients"
                                type="number"
                                name="clients"
                                class="form-control w-50"
                                v-model="formData.no_of_clients"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="table-column">Visit Description</td>
                        <td class="table-divider"></td>
                        <td class="table-row investigation-form"> 
                            <textarea v-model="formData.visit_description" class="form-control w-50" :rows="1"></textarea>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12 mb-1">
                <SubmitCancelBtn 
                    class="text-center"
                    @onSubmit="submit"
                    @onCancel="onClickCloseButton"
                    :loading="loading"
                    submitTxt="Update"
                    :isShowSubmitBtn="!isManagerView"
                >
                    <template #additional-buttons>
                        <button
                            v-if="!isManagerView"
                            :disabled="loading" @click="updateVisitPlanStatus('completed')"
                            class="btn btn-success me-1 mt-1 waves-effect waves-float waves-light"
                        >
                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Complete
                        </button>
                    </template>
                    <template #additional-buttons-top>
                        <button
                            v-if="isManagerView"
                            :disabled="loading" @click="updateVisitPlanStatus('approved')"
                            class="btn btn-success me-1 mt-1 waves-effect waves-float waves-light"
                        >
                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Approved
                        </button>
                        <button
                            v-if="isManagerView"
                            :disabled="loading" @click="updateVisitPlanStatus('rejected')"
                            class="btn btn-danger me-1 mt-1 waves-effect waves-float waves-light"
                        >
                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Reject
                        </button>
                    </template>
                </SubmitCancelBtn>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                <VisitContact
                    title="All Client List"
                    :tableItems="clientList"
                    :activityLogStatus="activityLogStatus"
                />
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                <VisitContact
                    title="New Clients / Leads"
                    btnTitle="Add new Client"
                    :tableItems="leadClients"
                    :singlePlan="singlePlan"
                    :activityLogStatus="activityLogStatus"
                    @onCreateNewProfile="onCreateNewProfile"
                />
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
    import { inject, onMounted, reactive, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import Loader from "@/components/atom/LoaderComponent";
    import VisitContact from '@/components/molecule/company/crm/visit-plan/VisitContact.vue';
    import handleCrm from '@/services/modules/crm/crm.js'
    import { computed } from 'vue';

    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const loader = ref(false);
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const singlePlan = ref({});
    const { fetchSingleVisitPlan, updateVisitStatus, updatePlanStatus, fetchVisitClientList, fetchActivityLogStatus } = handleCrm();
    const planId = ref(null)
    const clientList = ref([]);
    const leadClients = ref([]);
    const activityLogStatus = ref([]);

    const isManagerView = computed(() => {
        return route.query.viewType && route.query.viewType === 'manager';
    })

    const formData = ref({
        no_of_clients: null,
        visit_description: ''
    });
    
    const onClickCloseButton = () => {
        router.push({
            name: route.query.from ? route.query.from : 'visit-plan-list',
            params: route.params,
            query: { start: route.query.start, end: route.query.end}
        })
    }

    const updateVisitPlanStatus = async (status) => {
        
        const planData = {
            status: status,
            company_id: route.params.companyId
        }

        loading.value = true

        await updatePlanStatus(planData, planId.value).then((res) => {
            loading.value = false
            if (!res.status) return showError(res.message);
            onClickCloseButton();
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const submit = async () => {
        
        formData.value.company_id = route.params.companyId;

        loading.value = true

        await updateVisitStatus(formData.value, planId.value).then((res) => {
            loading.value = false
            if (!res.status) return showError(res.message);
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    function getQuery() {
        let query = `?company_id=${route.params.companyId}`;
        return query;
    }

    const getActivityLogStatus = async () => {
        const query = getQuery();

        await fetchActivityLogStatus(query).then((res) => {
            if(res.status) activityLogStatus.value = res.data
        })
    }

    const getSinglePlan = async () => {

        if(!planId.value){
            return showError('Visit plan id is required');
        }

        const query = getQuery();
        loader.value = true

        await fetchSingleVisitPlan(query, planId.value).then(async (res) => {

            if(!res.status) return showError(res.message);
            singlePlan.value = res.data;
            setEditData();

            await getVisitClientsList(query)

        }).finally(() => {
            loader.value = false
        })
    }

    const onCreateNewProfile = async () => {
        await getVisitClientsList(getQuery())
    }

    const getVisitClientsList = async (query) => {
       
        if(!singlePlan.value.dealer_id) return;

        leadClients.value = [];
        clientList.value = [];
        query += `&visit_plan_id=${singlePlan.value.id}`

        await fetchVisitClientList(query, singlePlan.value.dealer_id).then(res => {
            if(!res.status) return;
            setClientData(res.data)
        })
    }

    const setClientData = (data) => {
        for (const item of data) {
            if(item.role_names.split(',').includes('lead')){
                leadClients.value.push(item);
                continue;
            }
            clientList.value.push(item);
        }
    }

    const setEditData = () => {
        formData.value.no_of_clients = singlePlan.value.no_of_clients
        formData.value.visit_description = singlePlan.value.visit_description
    }

    onMounted( async () => {
        planId.value = route.params.planId

        await getActivityLogStatus();
        await getSinglePlan()
    })

</script>

<style scoped>
.table-column {
    padding: 10px 0;
}
.table-divider{
    padding: 3px 10px;
}
.table-divider:after {
    content: ":";
}
.table-row {
    width: 78%;
}
address{
    margin-bottom: 0 !important;
}
</style>