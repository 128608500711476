<template>
    <div class="card card-height">
        <div class="card-header border-bottom p-1">
            <h3 class="card-title">{{ title }}</h3>
            <div v-if="btnTitle">
                <button @click="addNewContact" type="button" class="btn btn-primary btn-sm me-1">{{ btnTitle }}</button>
            </div>
        </div>
        <div class="mt-2">
            <div class="">
                <table class="table custom-table-padding">
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Client Name</th>
                            <th>Billing Status</th>
                            <th>Statements</th>
                            <th>Visit History</th>
                            <th>Visit Note</th>
                            <th class="action-th">Next Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in tableItems" :key="item.id">
                            <td>{{ i + 1 }}</td>
                            <td>{{ item.full_name }}</td>
                            <td>Due</td>
                            <td>Ok</td>
                            <td>View</td>
                            <td>
                                <textarea 
                                    v-model="item.short_description" 
                                    @blur="saveVisitStatus(item)" 
                                    class="form-control w-100" 
                                    :rows="1">
                                </textarea>
                            </td>
                            <td class="action-row">
                                <div class="action">
                                    <v-select
                                        placeholder="Select Status"
                                        :options="activityLogStatus"
                                        :reduce="option => option.value"
                                        label="name" class="sm w-100"
                                        v-model="item.visit_status"
                                        @option:selected="value => saveVisitStatus(item, value)"
                                    />
                                    <button @click="goToSalesOrder(item)" class="btn btn-primary btn-sm create-order-btn">
                                        Create Order
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
        <AddContactModal
            v-if="store.state.isModalOpenTwo"
            @onCreateProfile="onCreateProfile"
            role="lead"
            :additionalProperties="{ dealer_id: singlePlan.dealer_id }"
        />
    </div>
</template>

<script setup>

    import handleCrm from '@/services/modules/crm/crm'
    import { inject, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import AddContactModal from '@/components/atom/Contact/AddContactModal'

    const props = defineProps({
        title: String,
        btnTitle: {
            type: String,
            default: ''
        },
        tableItems: {
            type: Array,
            default: []
        },
        activityLogStatus: {
            type: Array,
            default: []
        },
        singlePlan: {
            type: Object,
            default: {}
        },
        onCreateNewProfile: {
            type: Function,
            default: () => {}
        }
    })

    const emit = defineEmits(['onCreateNewProfile'])

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { storeVisitPlanLog, updateVisitPlanLog } = handleCrm();

    const goToSalesOrder = (contact) => {

        const routeData = router.resolve({
            name: 'sales-order-add',
            params: route.params,
            query: { visit_client_id: contact.id }
        });

        window.open(routeData.href, '_blank');
    }

    const onCreateProfile = (profileInfo) => {
        emit('onCreateNewProfile', profileInfo);
    }

    const addNewContact = () => {
        store.state.isModalOpenTwo = true
    }

    const saveVisitStatus = async (item) => {

        const activityLogId = item.activity_log_id;
        const visitPlanAction = activityLogId ? updateVisitPlanLog : storeVisitPlanLog;
        const formData = formatFormData(item);

        loading.value = true

        await visitPlanAction(formData, activityLogId).then((res) => {

            if (!res.status) return showError(res.message);

            setLogData(item, res.data);

        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const setLogData = (contact, data) => {
        const singleContact = props.tableItems.find(item => item.id === contact.id);
        if(! singleContact) return;

        singleContact.activity_log_id = data.activity_log_id
        singleContact.visit_status = data.visit_status
        singleContact.short_description = data.short_description
    }

    const formatFormData = (item) => {
        return {
            contact_profile_id: item.id,
            company_id: route.params.companyId,
            short_description : item.short_description,
            status: item.visit_status || 'active',
            visit_plan_id: route.params.planId,
            type: 'visit_note'
        }
    }
</script>

<style scoped>
.action-row {
    width: 22%;
}
.create-order-btn {
    padding: .2rem 0.4rem !important;
}
.action-th{
    text-align: center;
}
.note-row {
    width: 20%;
}
.action{
    text-align: center;
    display: flex;
    justify-content: space-around;
}

</style>
